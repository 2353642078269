// import 'animate.css'
// import 'bootstrap'
// import 'swiper/swiper-bundle.min.css'
// import './assets/scss/style.scss'
import 'normalize.css'
import { createRoot } from 'react-dom/client'
import App from '@/App'
import eruda from 'eruda'

// eruda.init()

// if (import.meta.env.MODE === 'development') {
// eruda.init()
// }

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App />)
